





import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PaypalCheckout extends Vue {
  @Prop({ default: 0 }) prop_amount!: number;
  @Prop() onclickfn!: Function;
  //https://stackoverflow.com/questions/45047126/how-to-add-external-js-scripts-to-vuejs-components
  //carga de script
  public commit: boolean = true;
  onAuthorize(data: any, actions: any) {
    // This function captures the funds from the transaction.
    return actions.order.capture().then((details: any) => {
      this.$emit("payment-completed", details);
      // This function shows a transaction success message to your buyer.
    });
  }
  onCancel(data: any) {
    this.$emit("payment-cancelled", data);
  }

  mounted() {
    this.refrescar_precio();
  }

  public refrescar_precio() {
    let am = this.prop_amount;
    const button = Object.assign({
      // Pass in the client ids to use to create your transaction
      // on sandbox and production environments
      client: "sandbox",

      // Display a "Pay Now" button rather than a "Continue" button
      commit: this.commit,

      // Pass a function to be called when the customer completes the payment

      onApprove: this.onAuthorize,
      onClick: this.onclickfn,
      onInit: (data: any, actions: any) => {
        // Disable the buttons
        this.$emit("on-init", actions);
      },
      // Pass a function to be called when the customer cancels the payment
      onCancel: this.onCancel,

      createOrder: function (data: any, actions: any) {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: am,
              },
            },
          ],
        });
      },
    });
    try {
      //@ts-ignore
      paypal.Buttons.instances[0].close();
    } catch (error) {}

    //@ts-ignore
    paypal.Buttons(button).render(this.$el);
  }
}
